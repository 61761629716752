<template>
  <div>
    <b-card>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="create()"
          ref="kt_save_changes"
          v-if="!editUserdepartment"
        >
          Add New User Department
        </button>
      </div>
      <div v-if="!editUserdepartment">
        <b-table
          striped
          hover
          sort-icon-left          
          class='clickabletd'
          :items="currentUserdepartments"
          :fields="userdepartmentFields"
          @row-clicked="rowClickHandler"
          :tbody-tr-class="rowClass"         
        >
          <template #cell(actions)="data">
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="data.item.active"
              >mdi-delete</v-icon
            >
            <v-icon small v-on:click="deleteRecord(data.item.id,data.item.active)" v-if="!data.item.active"
              >mdi-restart</v-icon
            >
          </template>
        </b-table>
      </div>

      <div v-if="editUserdepartment">
        <KTUserdepartmentInformation
          :record="editUserdepartmentRecord"
          @canceled="editUserdepartment = false"
          @finished="finished()"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_USERDEPARTMENTS,
  DELETE_USERDEPARTMENT_DETAILS,
} from "@/core/services/store/userdepartments.module";

import KTUserdepartmentInformation from "@/view/pages/management/userdepartments/UserdepartmentInformation";

export default {
  name: "workplans",
  components: {
    KTUserdepartmentInformation,
  },
  data() {
    return {
      editUserdepartment: false,
      editUserdepartmentRecord: [],

      userdepartmentFields: [
        { key: "name", sortable: true },
        { key: "description", sortable: true },
        { key: "actions", label: "" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Manage User Departments" }]);
    this.getData();
  },
  methods: {
    getData() {
      this.$store.dispatch(GET_USERDEPARTMENTS);
    },
    finished() {
      this.editUserdepartment = false;
      this.editUserdepartmentRecord = [];
      setTimeout(() => {
        this.getData();
      }, 500);
    },
    rowClickHandler(record, index) {
      console.log(record, index); // This will be the item data for the row
      this.editUserdepartmentRecord = record;
      this.editUserdepartment = true;
    },

    create() {
      this.editUserdepartmentRecord = {
        id: -1,
        name: "",
        description: "",
        settings: { 
          editing: 
            { 
              tasks: false, 
              actions: false, 
              approval: false, 
              exceptions: false 
            }, 
          viewing: 
            {  
              viewing: false 
            }, 
          auditing: 
            { 
              audit: false 
            }, 
          administrator: 
            { 
              users: false, 
              subscriptions: false 
            } 
        }
      };
      this.$nextTick(() => {
        this.editUserdepartment = true;
      });      
    },
    rowClass(item, type) {
      console.log(type,item)
        if (item.active != true) { 
          return 'text-deleted'
        } else {
          return ''
        } 
    },        
    deleteRecord(id,active) {
      var text = "Do you really want to delete?";
      if (!active ) {text = "Do you really want to restore?"; }
      if (confirm(text)) {
        this.editUserdepartment = false;
        this.$store.dispatch(DELETE_USERDEPARTMENT_DETAILS, {
          id,
        });
        setTimeout(() => {
          this.getData();
        }, 500);
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserdepartments"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getFrameworkHeaders() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {
            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
            });
          }
        });
      }
      return results;
    },
  },
};
</script>
