<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id != -1"
        >
          Edit User Department
        </h3>
        <h3
          class="card-label font-weight-bolder text-dark"
          v-if="record.id == -1"
        >
          Create User Department
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
        <button type="reset" class="btn btn-secondary" @click="cancel()">
          Cancel
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" v-if="userdepartmentRecord">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">Name</label>
          <div class="col-lg-9 col-xl-6">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.userdepartmentRecord.name.$model"
            />
            <div class="error" v-if="!$v.userdepartmentRecord.name.required">
              <i>Name is required</i>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Description</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              ref="description"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model.trim="$v.userdepartmentRecord.description.$model"
            />
            <div class="error" v-if="!$v.userdepartmentRecord.description.required">
              <i>Description is required</i>
            </div>
          </div>
        </div>

      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  UPDATE_USERDEPARTMENT_DETAILS,
  CREATE_USERDEPARTMENT_DETAILS,
} from "@/core/services/store/userdepartments.module";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "PersonalInformation",
  props: {
    record: Object,
  },
  data() {
    return {
      userdepartmentRecord: this.record,
    };
  },
  validations: {
    userdepartmentRecord: {
      name: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    console.log('record',this.userdepartmentRecord);
  },
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        var name = this.$refs.name.value;
        var description = this.$refs.description.value;
        var id = this.userdepartmentRecord.id;

        // set spinner to submit button
        const submitButton = this.$refs["kt_save_changes"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (id == -1) {
          // send update request
          this.$store
            .dispatch(CREATE_USERDEPARTMENT_DETAILS, {
              id,
              name,
              description,
            })
            .then(() => {
              this.$emit("finished");
            });
        } else {
          // send update request
          this.$store
            .dispatch(UPDATE_USERDEPARTMENT_DETAILS, {
              id,
              name,
              description,
            })
            .then(() => {
              this.$emit("finished");
            });
        }

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    cancel() {
      this.$emit("canceled");
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    record: function(newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
    },
  },
};
</script>
<style>
.error i {
  color: #cc0000;
}
</style>
